export const PAGINATION_CONSTANT = {
    PAGE_SIZE: 10,
    PAGE_NUMBER: 1
};

export const DESIGNATION_PAGINATION_CONSTANT = {
    PAGE_SIZE: 5,
    PAGE_NUMBER: 1
};

export const pageProperties = {
    WIDTH: 1024,
    OVERFLOW_Y: 250
};

export const STORAGE_CONST = {
    TOKEN: "TOKEN",
    PUBLIC_TOKEN: "PUBLIC_TOKEN",
    THEME_TYPE: "theme_type",
    USER_INFO: "USER_INFO",
    EMAIL: "EMAIL",
    USER_ID: "USER_ID"
};

export const LOGOUT_KEYS = [
    STORAGE_CONST.TOKEN,
    STORAGE_CONST.PUBLIC_TOKEN,
    STORAGE_CONST.USER_INFO,
    STORAGE_CONST.EMAIL,
    STORAGE_CONST.USER_ID
];

export const USER_ROLE = {
    GLOBAL_ADMIN: { text: "Global Admin", value: "GlobalAdmin" },
    USER: { text: "User", value: "User" },
    REGIONAL_ADMIN: { text: "Regional Admin", value: "RegionalAdmin " }
};

export const REPORT_CITIES = {
    Austin_TX: "Austin, TX",
    Bastrop_County_TX: "Bastrop County, TX",
    Dallas_TX: "Dallas, TX",
    Douglas_County_OR: "Douglas County, OR",
    El_Paso_TX: "El Paso, TX",
    Fort_Worth_TX: "Fort Worth, TX",
    Houston_TX: "Houston, TX",
    Huntsville_AL: "Huntsville, AL",
    Iowa: "Iowa",
    Maine: "Maine",
    Massachusetts: "Massachusetts",
    Nevada: "Nevada",
    San_Antonio_TX: "San Antonio, TX",
    Walla_Walla_OR: "Walla Walla, OR",
    Walla_Walla_WA: "Walla Walla, WA"
};

export const APP_NAME = "THE BEST PLACE FOR WORKING PARENTS";
export const APP_NAME_MINI = "BP4WP";
export const FOOTER_TEXT_APP_NAME = "Copyright 2021 - BEST PLACE FOR WORKING PARENTS. In collaboration with";
export const companyName = `Unique Software Development.`;
export const regionStateQuestion = "From which state is your business seeking a Best Place for Working Parents® designation?  (Note: You will be able to select additional states/regions where your company does business.)"

export const THEME = {
    LITE: "light",
    DARK: "dark"
};

export const QUIZ_STATUS = {
    Invalid: "Invalid",
    Rejected: "Rejected",
    Incomplete: "Incomplete",
    Approved: "Approved"
};
export const QUIZ_ACTION_TYPE = {
    SEND_CERTIFICATE: 100,
    UPLOAD_LOGO: 200,
    VIEW_PROFILE: 300,
    SHARE_ACCESS: 400,
    DELETE: 500,
    RESUME: 600,
    INACTIVATE: 700,
    SEND_REMINDER: 800,
    SEND_LOGIN_EMAIL: 900,
    EDIT_CONTACT: 1000,
    DOWNLOAD_LOGO: 1100,
    SEND_RENEWAL_EMAIL: 1200,
    EDIT_QUIZ: 1300
};

export const AccountStatus = {
    Disabled: "Disabled",
    Inactive: "Inactive",
    Invited: "Invited",
    Active: "Active"
};

export const SORT_ORDER = {
    ASC: "ascend",
    DESC: "descend"
};

export const exportTypes = {
    CSV: "csv"
};
export const REGION_FILTER_TYPE = {
    State: 1,
    City: 2
};

export const QUESTION_TYPE = {
    MULTIPLE: "Multiple",
    TEXT: "Text",
    CHOICE: "Choice",
    BOOLEAN: "Boolean",
    BOOLEAN_SELECTION: "BooleanSelection"
};
export const QUESTION_OPTIONS = ["A", "B", "C", "D"];

export const QUESTION_OPTIONS_MHB_SELECTION = ["A", "B", "C", "D", "E", "F"]

export const OTHER_OPTION = "Other";

export const LABEL_PLACEHOLDERS = {
    1: {
        placeholder: "Type your answer here...",
        name: "fullName",
        message: "Full Name is Required.",
        restrictNumber: true
    },
    2: { placeholder: "Type your answer here...", name: "companyName", message: "Company Name is Required." },
    3: { placeholder: "Type your answer here...", name: "jobTitle", message: "Job Title is Required." },
    4: [
        { placeholder: "Type or select an option....", name: "state", message: "State is required." },
        { placeholder: "Type or select an option....", name: "name", message: "City is required." }
    ],
    5: { placeholder: "Select State", name: "otherCities", message: "Other cities is required." },
    6: {
        placeholder: "Select an option....",
        name: "companySize",
        message: "Company Size Selection is Required"
    },
    7: {
        placeholder: "Type or select an option....",
        name: "industry",
        message: "Company Industry Selection is Required."
    },
    8: {
        placeholder: "Type your answer here...",
        name: "healthcareCoverage",
        message: "Employee Healthcare Selection is Required."
    },
    9: {
        placeholder: "Type your answer here...",
        name: "healthcareAccess",
        message: "Dependent Healthcare Selection is Required."
    },
    10: {
        placeholder: "Type your answer here...",
        name: "paidTimeOff",
        message: "Paid Time Off Selection is Required."
    },
    11: {
        placeholder: "Type your answer here...",
        name: "maternityLeave",
        message: "Maternity Leave Selection is Required."
    },
    12: {
        placeholder: "Type your answer here...",
        name: "paternityLeave",
        message: "Paternity Leave Selection is Required."
    },
    13: {
        placeholder: "Type your answer here...",
        name: "breastfeedingBenefits",
        message: "Breastfeeding Benefit Selection is Required."
    },
    14: {
        placeholder: "Type your answer here...",
        name: "organizationalCulture",
        message: "Company Recognition Selection is Required."
    },
    15: {
        placeholder: "Type your answer here...",
        name: "childCareOnsite",
        message: "Onsite Childcare Selection is Required."
    },
    16: { placeholder: "Type your answer here...", name: "FSA", message: "Offsite Childcare Selection is Required." },
    17: {
        placeholder: "Type your answer here...",
        name: "backupChildCare ",
        message: "Backup Childcare Selection is Required."
    },
    18: {
        placeholder: "Type your answer here...",
        name: "flexibilityTime",
        message: "Schedule Flexibility Selection is Required."
    },
    19: {
        placeholder: "Type your answer here...",
        name: "remotelyWork",
        message: "Remote Flexibility Selection is Required."
    },
    20: { placeholder: "Type your answer here...", name: "policies", message: "At least one is required." },
    22: { placeholder: "Type your answer here...", name: "renewal", message: "" },
    21: {name: "mentalHealthBenefits", message: "At least one is required."},
    24: {name: "isMentalHealthSelected", message: "Mental Health Benefits Selection is required."},
    23: { placeholder: "Type your answer here...", name: "overview", message: "At least one is required." }
};

export const QUESTION_STEPS = {
    fullName: 1,
    companyName: 2,
    jobTitle: 3,
    region: 4,
    multiRegion: 5,
    companySize: 6,
    industry: 7,
    healthcareCoverage: 8,
    healthcareAccess: 9,
    paidTimeOff: 10,
    maternityLeave: 11,
    paternityLeave: 12,
    breastfeedingBenefits: 13,
    organizationalCulture: 14,
    childCareOnsite: 15,
    FSA: 16,
    backupChildCare: 17,
    flexibilityTime: 18,
    remotelyWork: 19,
    policies: 20,
    renewal: 22,
    mentalHealthBenefits: 21,
    overview: 23
};

export const INITIAL_VALUES = {
    fullName: "",
    companyName: "",
    jobTitle: "",
    state: undefined,
    name: undefined,
    otherCities: [{}],
    companySize: undefined,
    industry: undefined,
    healthcareCoverage: "",
    healthcareAccess: "",
    paidTimeOff: "",
    maternityLeave: "",
    paternityLeave: "",
    breastfeedingBenefits: "",
    organizationalCulture: "",
    childCareOnsite: "",
    FSA: "",
    backupChildCare: "",
    flexibilityTime: "",
    remotelyWork: "",
    mentalHealthBenefits: [],
    policies: ""
};

export const COMPANY_SIZE = {
    0: "Micro (0 - 24)",
    25: "Small (25 - 200)",
    201: "Medium (201 - 999)",
    1000: "Large (1,000+)"
};

export const COMPANY_TYPE = {
    0: "Micro",
    25: "Small",
    201: "Medium",
    1000: "Large"
};

export const GENERAL_TEXT = [QUESTION_STEPS.fullName, QUESTION_STEPS.jobTitle, QUESTION_STEPS.renewal];
export const POLICY = [QUESTION_STEPS.policies];
export const COMPANY_NAME = [QUESTION_STEPS.companyName];
export const REGION = [QUESTION_STEPS.region];
export const MULTI_REGION = [QUESTION_STEPS.multiRegion];
export const COMPANY_SIZ = [QUESTION_STEPS.companySize];
export const INDUSTRY = [QUESTION_STEPS.industry];
export const GENERAL_BOOLEAN = [
    QUESTION_STEPS.maternityLeave,
    QUESTION_STEPS.paternityLeave,
    QUESTION_STEPS.breastfeedingBenefits,
    QUESTION_STEPS.childCareOnsite,
    QUESTION_STEPS.FSA,
    QUESTION_STEPS.backupChildCare,
    QUESTION_STEPS.flexibilityTime,
    QUESTION_STEPS.remotelyWork,
];

export const GENERAL_MULTIPLE = [
    QUESTION_STEPS.healthcareCoverage,
    QUESTION_STEPS.healthcareAccess,
    QUESTION_STEPS.paidTimeOff,
    QUESTION_STEPS.organizationalCulture
];

export const OVERVIEW = [QUESTION_STEPS.overview];
export const MENTAL_HEALTH_BENEFITS = [QUESTION_STEPS.mentalHealthBenefits];
export const PROGRESS_BAR_VALUE = 4.76;

export const CARD_INFO = {
    coreBenefits: {
        title: "Core Benefits that are considered basic in a business include:",
        benefits: ["Healthcare Coverage", "Paid Time Off", "Parental Leave"]
    },
    organizationalCulture: {
        title: "Organizational Culture is related to the culture and policies that the company promotes.",
        benefits: ["Breastfeeding Benefits", "Won awards related to work environment"]
    },
    familyResources: {
        title: "Family Resources are related to the benefits that help or support family care. These include:",
        benefits: ["Onsite Childcare", "Child Care Assistance", "Backup Child Care"]
    },
    flexibility: {
        title: "Flexibility Benefits are related to benefits that show the ability of bending around the employee schedules. These include:",
        benefits: ["Flexible Hours", "Remote Work / Working from Home"]
    }
};

export const BANNER_INFO = {
    HealthCare: {
        title: "Healthcare",
        subTitle: (isProviding) => `${isProviding ? "Yes, you offer" : "No, you don't offer"} healthcare coverage.`,
        sizeInfo: (size) => `Share of ${size} businesses that offer healthcare coverage`,
        industryInfo: (size, industry) => `Share of ${size} ${industry} businesses that offer healthcare coverage`,
        icon: "medical"
    },
    DependantHealthCare: {
        title: "Dependent Healthcare",
        subTitle: (isProviding) => `${isProviding ? "Yes, you offer" : "No, you don't offer"} healthcare to dependents`,
        sizeInfo: (size) => `Share of ${size} businesses that offer healthcare to dependents`,
        industryInfo: (size, industry) => `Share of ${size} ${industry} businesses that offer healthcare to dependents`,
        icon: "medical"
    },
    PaidTimeOff: {
        title: "Paid Time Off",
        subTitle: (isProviding) => `${isProviding ? "Yes, you offer" : "No, you don't offer"} paid time off`,
        sizeInfo: (size) => `Share of ${size} Technology businesses that paid time off`,
        industryInfo: (size, industry) => `Share of ${size} ${industry} businesses that offer heathcare coverage`,
        icon: "paid-time-off"
    },
    MaternityLeave: {
        title: "Maternity Leave",
        subTitle: (isProviding) => `${isProviding ? "Yes, you offer" : "No, you don't offer"} maternity leave`,
        sizeInfo: (size) => `Share of ${size} businesses that offer maternity leave`,
        industryInfo: (size, industry) => `Share of ${size} ${industry} that offer maternity leave`,
        icon: "maternity"
    },
    PaternityLeaves: {
        title: "Paternity Leave",
        subTitle: (isProviding) => `${isProviding ? "Yes, you offer" : "No, you don't offer"} paternity leave`,
        sizeInfo: (size) => `Share of ${size} businesses that offer paternity leave`,
        industryInfo: (size, industry) => `Share of ${size} ${industry} businesses that offer paternity leave`,
        icon: "maternity"
    },
    BreastFeeding: {
        title: "Breastfeeding",
        subTitle: (isProviding) => `${isProviding ? "Yes, you offer" : "No, you don't offer"} breastfeeding benefits`,
        sizeInfo: (size) => `Share of ${size} businesses that offer breastfeeding benefits`,
        industryInfo: (size, industry) => `Share of ${size} ${industry} businesses that offer breastfeeding benefits`,
        icon: "breastfeeding"
    },
    RelatedAwards: {
        title: "Related Awards",
        subTitle: (isProviding) =>
            `${isProviding ? "Yes, you receive" : "No, you didn't receive"} other awards for work environment`,
        sizeInfo: (size) => `Share of ${size} businesses that have awards for work environment`,
        industryInfo: (size, industry) =>
            `Share of ${size} ${industry} businesses that have awards for work environment`,
        icon: "thumbs-up"
    },
    FlexibleHours: {
        title: "Flexible Hours",
        subTitle: (isProviding) => `${isProviding ? "Yes, you offer" : "No, you don't offer"} flexible hours benefits`,
        sizeInfo: (size) => `Share of ${size} businesses that allow flexible work hours`,
        industryInfo: (size, industry) => `Share of ${size} ${industry} businesses that allow flexible work hours`,
        icon: "flexible-hours"
    },
    RemoteWork: {
        title: "Remote Work",
        subTitle: (isProviding) =>
            `${
                isProviding
                    ? "Yes, you received other awards for work environment"
                    : "No, you don't allow working from home"
            }`,
        sizeInfo: (size) => `Share of ${size} businesses that allow working from home`,
        industryInfo: (size, industry) => `Share of ${size} ${industry} businesses that allow working from home`,
        icon: "working-remotely"
    },
    OnsiteChildCare: {
        title: "Onsite Childcare",
        subTitle: (isProviding) => `${isProviding ? "Yes, you offer" : "No, you don't offer"} onsite childcare`,
        sizeInfo: (size) => `Share of ${size} businesses that offer onsite childcare`,
        industryInfo: (size, industry) => `Share of ${size} ${industry} businesses that offer onsite childcare`,
        icon: "onsite-childcare"
    },
    ChildcareAssistance: {
        title: "Childcare Assistance",
        subTitle: (isProviding) =>
            `${isProviding ? "Yes, you offer" : "No, you don't offer"} childcare assistance, FSA, vouchers, etc`,
        sizeInfo: (size) => `Share of ${size} businesses that offer childcare assistance, FSA, vouchers, etc`,
        industryInfo: (size, industry) =>
            `Share of ${size} ${industry} businesses that offer childcare assistance, FSA, vouchers, etc`,
        icon: "childcare-assistance"
    },
    BackupChildcare: {
        title: "Backup Childcare",
        subTitle: (isProviding) => `${isProviding ? "Yes, you offer" : "No, you don't offer"} backup childcare`,
        sizeInfo: (size) => `Share of ${size} businesses that offer backup childcare`,
        industryInfo: (size, industry) =>
            `Share of ${size} ${industry} Technology businesses that offer backup childcare`,
        icon: "backup-childcare"
    }
};

export const SIZE_BASED_SCORE = {
    Micro: "30%",
    Small: "30%",
    Medium: "60%",
    Large: "60%"
};

export const PDF_REMOVE_IDS = {
    HEADER: "header-container",
    UPLOAD: "upload-component",
    SEND_PDF_ROW: "send-pdf-row",
    FOOTER: "footer"
};

export const PASSWORD_VALIDATION_TYPE = {
    MIN: "MIN",
    LOWERCASE: "LOWERCASE",
    UPPERCASE: "UPPERCASE",
    NUMBER: "NUMBER",
    SPECIALCHARACTER: "SPECIALCHARACTER",
    REQUIRED: "REQUIRED"
};

